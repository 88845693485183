import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import useUsers from '../../context/UsersContext';
import appService from '../../service/app.service';
import usePages from '../../context/PageContext';
import biService from '../../service/bi.service';
import dashboardService from '../../service/dashboard.service';
import { DebouncedInputUI } from '../Interface';

const NavigationItem = ({ item, setSidebarOpen, filterSubMenuName }) => {
  const [visible, setVisible] = useState(false);
  const { setPages } = usePages();

  const handleToggleVisible = () => {
    setVisible((prevVisible) => !prevVisible);
  };

  const handleSubMenuClick = (submenu) => {
    setPages({ menuName: item.menuName, menuId: item.menuId, ...submenu });
    setSidebarOpen(false);
  };

  useEffect(() => {
    if (filterSubMenuName) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [filterSubMenuName]);

  return (
    <div className="relative my-3 text-white cursor-pointer ml-3">
      <div
        className="flex items-center justify-between hover:bg-gray-800 hover:text-white rounded-md gap-x-3 px-2"
        onClick={handleToggleVisible}
      >
        <p className="font-semibold text-md py-3">{item.menuName}</p>
        <div className="py-3 px-3">
          {visible === false ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
            </svg>
          )}
        </div>
      </div>
      {visible && (
        <ul className="mt-1 space-y-1 px-6">
          {item.submenus.map((subItem) => (
            <Link to={subItem.url === '#' ? '/' : `/app/${subItem.url}`} className="block w-full">
              <li
                onClick={() => handleSubMenuClick(subItem)}
                key={subItem.subMenuName}
                className="text-gray-400 hover:bg-gray-800 hover:text-white group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
              >
                {subItem.subMenuName}
              </li>
            </Link>
          ))}
        </ul>
      )}
    </div>
  );
};

const NavigationMenu = ({ setSidebarOpen }) => {
  const [menuData, setMenuData] = useState([]);
  const [filteredMenuData, setFilteredMenuData] = useState([]);
  const [usersubMenuIds, setUsersubMenuIds] = useState([]);
  const [biReportIds, setBiReportIds] = useState([]);
  const [biReports, setBiReports] = useState([]);
  const [dashboardIds, setDashboardIds] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [filterSubMenuName, setFilterSubMenuName] = useState('');

  const { user, getUser } = useUsers();

  const fetchUserPermissions = (roleId) => {
    appService
      .getUserPermissions(roleId)
      .then((data) => {
        setUsersubMenuIds(data);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  const fetchMenuData = () => {
    appService
      .getMenuData()
      .then((data) => {
        setMenuData(data);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  const getBiRolePermission = (roleId) => {
    appService.getBiPermissions(roleId).then((data) => {
      setBiReportIds(data);
    });
  };

  const getDashboardPermission = (roleId) => {
    appService.getDashboardPermissions(roleId).then((data) => {
      setDashboardIds(data);
    });
  };

  const getBiReports = () => {
    biService.allBiReports().then((data) => {
      setBiReports(data);
    });
  };

  const getDashboards = () => {
    dashboardService.allDashboards().then((data) => {
      setDashboards(data);
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (user) {
      fetchUserPermissions(user.roleId);
      getBiRolePermission(user.roleId);
      getDashboardPermission(user.roleId);
      fetchMenuData();
      getBiReports();
      getDashboards();
    }
  }, [user]);

  useEffect(() => {
    if (menuData.length > 0 && usersubMenuIds.length > 0) {
      const filteredMenu = menuData
        .map((item) => {
          let biReportSubmenus = [];
          if (item.url === 'bi') {
            biReportSubmenus = biReports
              .filter((report) => biReportIds.some((biReportPermission) => biReportPermission.biReportId === report.id))
              .map((report) => ({
                subMenuId: uuidv4(),
                subMenuName: report.name,
                url: `bi/biPage/${report.id}`,
                parentMenuId: item.menuId,
                archive: 'N',
                isFormType: false,
              }));
          }

          let dashboardSubmenus = [];
          if (item.url === 'dashboard') {
            dashboardSubmenus = dashboards
              .filter((report) =>
                dashboardIds.some((dashboardPermission) => dashboardPermission.dashboardId === report.id)
              )
              .map((report) => ({
                subMenuId: uuidv4(),
                subMenuName: report.name,
                url: `dashboard/${report.id}`,
                parentMenuId: item.menuId,
                archive: 'N',
                isFormType: false,
              }));
          }
          const filteredSubmenus = item.submenus
            .filter((subItem) => usersubMenuIds.some((userSubMenu) => userSubMenu.subMenuId === subItem.subMenuId))
            .filter((subItem) => subItem.subMenuName.toLowerCase().includes(filterSubMenuName.toLowerCase()));

          const filteredBiReportSubmenus = biReportSubmenus.filter((subItem) =>
            subItem.subMenuName.toLowerCase().includes(filterSubMenuName.toLowerCase())
          );

          const filteredDashboardSubmenus = dashboardSubmenus.filter((subItem) =>
            subItem.subMenuName.toLowerCase().includes(filterSubMenuName.toLowerCase())
          );

          return {
            ...item,
            submenus: [...filteredSubmenus, ...filteredBiReportSubmenus, ...filteredDashboardSubmenus],
          };
        })
        .filter((item) => item.submenus.length > 0);
      setFilteredMenuData(filteredMenu);
    }
    //88szor setel de működik -> promise all
  }, [menuData, usersubMenuIds, biReportIds, filterSubMenuName, biReports, dashboards, dashboardIds]);

  return (
    <nav>
      <div className="px-5">
        <DebouncedInputUI
          label={<span className="text-white">Keresés</span>}
          sx={{
            '& .MuiInputBase-input': {
              color: '#fff',
            },
          }}
          debounceMs={800}
          setQuickFilterSearchValue={(newValue) => {
            setFilterSubMenuName(newValue);
          }}
          quickFilterSearchValue={filterSubMenuName}
        />
      </div>
      {filteredMenuData.map((item, index) => (
        <NavigationItem key={index} item={item} setSidebarOpen={setSidebarOpen} filterSubMenuName={filterSubMenuName} />
      ))}
    </nav>
  );
};

export default NavigationMenu;
