import React from 'react';
import { Field } from 'formik';
import { Typography } from '@material-tailwind/react';

import { FormErrorUI, FormLabelUI, AutoCompleteSelectUI } from '../../Interface';

const BiFilterFields = ({ values, validateField, setFieldValue, errors, autocompleteData, fieldErrorState }) => {
  const { fieldErrors } = fieldErrorState;
  return (
    <>
      <div className="mb-4">
        <Typography variant="h6" className="mr-2">
          Szűrhető mezők
        </Typography>
      </div>
      <div className="flex flex-col gap-y-4 mx-2 mb-2">
        {fieldErrors.length > 0 &&
          fieldErrors.map((error) => (
            <div key={error.id}>
              <FormErrorUI message={error.message} />
            </div>
          ))}
      </div>
      <div className="flex flex-col mx-4">
        <div className="w-1/4">
          <Field
            type="autocomplete"
            name="bpField"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text="Üp szűrő" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.bpField} />}
            inputLabelProps={{ shrink: values.bpField !== null }}
            onChange={(_e, newVal) => {
              setFieldValue('bpField', newVal?.value ?? null).then(() => {
                validateField('bpField');
              });
            }}
            value={values.bpField ?? null}
            dataset={autocompleteData.biField}
            isOptionEqualToValue={(option, value) => option.value === value}
          />
        </div>
        <div className="w-1/4">
          <Field
            type="autocomplete"
            name="yearField"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text="Év szűrő" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.yearField} />}
            inputLabelProps={{ shrink: values.yearField !== null }}
            onChange={(_e, newVal) => {
              setFieldValue('yearField', newVal?.value ?? null).then(() => {
                validateField('yearField');
              });
            }}
            value={values.yearField ?? null}
            dataset={autocompleteData.biField}
            isOptionEqualToValue={(option, value) => option.value === value}
          />
        </div>
        <div className="w-1/4">
          <Field
            type="autocomplete"
            name="querterField"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text="Negyedév szűrő" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.querterField} />}
            inputLabelProps={{ shrink: values.querterField !== null }}
            onChange={(_e, newVal) => {
              setFieldValue('querterField', newVal?.value ?? null).then(() => {
                validateField('querterField');
              });
            }}
            value={values.querterField ?? null}
            dataset={autocompleteData.biField}
            isOptionEqualToValue={(option, value) => option.value === value}
          />
        </div>
        <div className="w-1/4">
          <Field
            type="autocomplete"
            name="monthField"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text="Hónap szűrő" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.monthField} />}
            inputLabelProps={{ shrink: values.monthField !== null }}
            onChange={(_e, newVal) => {
              setFieldValue('monthField', newVal?.value ?? null).then(() => {
                validateField('monthField');
              });
            }}
            value={values.monthField ?? null}
            dataset={autocompleteData.biField}
            isOptionEqualToValue={(option, value) => option.value === value}
          />
        </div>
        <div className="w-1/4">
          <Field
            type="autocomplete"
            name="dateInterValField"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text="Dátum intervallum szűrő" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.dateInterValField} />}
            inputLabelProps={{ shrink: values.dateInterValField !== null }}
            onChange={(_e, newVal) => {
              setFieldValue('dateInterValField', newVal?.value ?? null).then(() => {
                validateField('dateInterValField');
              });
            }}
            value={values.dateInterValField ?? null}
            dataset={autocompleteData.biField}
            isOptionEqualToValue={(option, value) => option.value === value}
          />
        </div>
      </div>
    </>
  );
};

export default BiFilterFields;
