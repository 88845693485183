import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Formik, Form } from 'formik';
import Yup from '../../../utils/yup';
import { isEqual } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import biService from '../../../service/bi.service';
import { TabsUI, ButtonUI } from '../../Interface';
import { NotificationType } from '../../../config';
import addNotification from '../../../utils/addNotification';
import BiFieldForm from './BiFieldForm';
import BiReportForm from './BiReportForm';
import BiReport from '../BiReport/BiReport';
import { BiReportType } from '../../../config/bi_report';
import BiDrillForm from './BiDrillForm';
import BiFilterFields from './BiFilterFields';

const BiEditorPage = () => {
  const navigate = useNavigate();
  const { reportId } = useParams();

  const defaultField = {
    field: null,
    type: '',
    name: '',
    isGroup: false,
    isFilter: false,
    filterType: null,
    analysis: null,
    thresholdMin: '',
    thresholdMax: '',
    thresholdArrow: false,
    filterFieldValue: [],
  };

  const defaultDrillField = {
    field: null,
    name: '',
    orderField: null,
    orderType: null,
    limit: '',
  };

  const defaultValues = useMemo(
    () => ({
      id: reportId,
      name: '',
      reportType: null,
      biHeadId: null,
      orderField: null,
      orderType: null,
      limit: '',
      biField: [],
      biDrilling: [],
      bpField: null,
      yearField: null,
      querterField: null,
      monthField: null,
      dateInterValField: null,
      biDrillingLink: {
        linkedReport: '',
        biDrillingLinkField: [],
      },
    }),
    [reportId]
  );

  const [initValues, setInitValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({
    biHeads: [],
    biField: [],
    biReports: [],
  });
  const [sourceData, setSourceData] = useState({
    biHeads: [],
    biField: [],
    biReports: [],
  });
  const [fieldErrors, setFieldErrors] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const [reRender, setReRender] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const getFields = useCallback(
    (newVal) => {
      if (newVal) {
        const findedView = sourceData.biHeads.find((biHead) => biHead.id === newVal);
        if (findedView) {
          biService.getViewFields(findedView.element).then((data) => {
            setSourceData((prev) => {
              return { ...prev, biField: data };
            });
          });
        } else {
          setSourceData((prev) => {
            return { ...prev, biField: [] };
          });
          return addNotification({
            content: 'Hiba történt a forrás kiválasztása során!',
            type: NotificationType.WARNING,
          });
        }
      } else {
        setSourceData((prev) => {
          return { ...prev, biField: [] };
        });
      }
    },
    [sourceData.biHeads]
  );

  const getReport = useCallback(() => {
    biService.getReport(reportId).then((data) => {
      setSelectedReport(data);
      getFields(data.biHeadId);
    });
  }, [reportId, getFields]);

  useEffect(() => {
    getAllBiHeads();
    getAllReports();
  }, []);

  useEffect(() => {
    if (reportId && sourceData.biHeads.length) {
      getReport();
    }
  }, [reportId, sourceData.biHeads, getReport]);

  useEffect(() => {
    if (
      Object.keys(selectedReport).length > 0 &&
      autocompleteData.biHeads.length > 0 &&
      autocompleteData.biField.length > 0
    ) {
      setInitValues({ ...selectedReport, biDrillingLink: selectedReport.biDrillingLink ?? {} });
    } else {
      setInitValues(defaultValues);
    }
  }, [selectedReport, autocompleteData.biHeads, autocompleteData.biField, defaultValues]);

  useEffect(() => {
    setAutocompleteData((prev) => {
      const newAutocompleteData = { ...prev };

      newAutocompleteData.biHeads =
        sourceData.biHeads.length > 0
          ? sourceData.biHeads.map((biHead) => ({
              value: biHead.id,
              label: biHead.name,
            }))
          : [];

      return newAutocompleteData;
    });
  }, [sourceData.biHeads]);

  useEffect(() => {
    setAutocompleteData((prev) => {
      const newAutocompleteData = { ...prev };

      newAutocompleteData.biField =
        sourceData.biField.length > 0
          ? sourceData.biField.map((field) => ({
              value: field.COLUMN_NAME,
              label: field.COLUMN_NAME,
            }))
          : [];

      return newAutocompleteData;
    });
  }, [sourceData.biField]);

  useEffect(() => {
    setAutocompleteData((prev) => {
      const newAutocompleteData = { ...prev };

      newAutocompleteData.biReports = [];
      sourceData.biReports.forEach((biReport) => {
        newAutocompleteData.biReports.push({
          value: biReport.id,
          label: biReport.name,
        });
      });

      return newAutocompleteData;
    });
  }, [sourceData.biReports]);

  useEffect(() => {
    if (reRender) setReRender(false);
  }, [reRender]);

  const getAllBiHeads = () => {
    biService.getAllBiHeads().then((data) =>
      setSourceData((prev) => {
        return { ...prev, biHeads: data };
      })
    );
  };

  const getAllReports = () => {
    biService.allBiReports().then((data) =>
      setSourceData((prev) => {
        return { ...prev, biReports: data };
      })
    );
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    reportType: Yup.string().required(),
    biHeadId: Yup.number().required(),
    orderField: Yup.string().nullable(),
    orderType: Yup.string().nullable(),
    limit: Yup.number().positive().min(1).max(25).nullable(),
    bpField: Yup.string().nullable(),
    yearField: Yup.string().nullable(),
    querterField: Yup.string().nullable(),
    monthField: Yup.string().nullable(),
    dateInterValField: Yup.string().nullable(),
    biField: Yup.array().of(
      Yup.object().shape({
        field: Yup.string().required(),
        type: Yup.string().test('is-required-based-on-reportType', 'Kötelező!', function (value) {
          if (this.options?.context) {
            const { reportType } = this.options?.context;
            return reportType === BiReportType.TABLE ? value != null : true;
          } else return false;
        }),
        name: Yup.string().test('is-required-based-on-isFilter', 'Kötelező!', function (value) {
          if (this.options?.parent) {
            const { isFilter } = this.options?.parent;
            return isFilter || !!value;
          } else return false;
        }),
        filterType: Yup.string()
          .test('is-required-based-on-isFilter', 'Kötelező!', function (value) {
            if (this.options?.parent) {
              const { isFilter } = this.options?.parent;
              return !isFilter || !!value;
            } else return false;
          })
          .nullable(),
        filterFieldValue: Yup.array()
          .of(Yup.string())
          .when('isFilter', {
            is: true,
            then: (schema) => schema.min(1).required('Kötelező!'),
            otherwise: (schema) => schema,
          }),

        analysis: Yup.string()
          .test('is-required-not-table', 'Kötelező!', function (value) {
            if (this.options?.context && this.options?.parent) {
              const { reportType } = this.options?.context;
              const { isFilter, isGroup } = this.options?.parent;

              if (reportType !== BiReportType.TABLE) {
                // ha nem filter vagy csoportosítás
                if (!isFilter && !isGroup) return !!value;
                else return true;
              } else return true;
            } else return false;
          })
          .nullable(),
        threshold: Yup.number().nullable(),
        thresholdMin: Yup.number().nullable(),
        thresholdMax: Yup.number().nullable(),
        thresholdArrow: Yup.boolean().nullable(),
      })
    ),
    biDrilling: Yup.array().of(
      Yup.object().shape({
        field: Yup.string().required(),
        name: Yup.string().required(),
        orderField: Yup.string().nullable(),
        orderType: Yup.string().nullable(),
        limit: Yup.number().positive().min(1).max(25).nullable(),
      })
    ),
    biDrillingLink: Yup.object()
      .shape({
        linkedReport: Yup.string().nullable(),
        biDrillingLinkField: Yup.array().of(
          Yup.object()
            .shape({
              parentField: Yup.string(),
              childField: Yup.string()
                .test('is-required-not-table', 'Kötelező!', function (value) {
                  if (this.options?.context) {
                    const { biDrillingLink } = this.options?.context;
                    if (biDrillingLink.linkedReport) {
                      return value;
                    } else return false;
                  } else return true;
                })
                .nullable(),
            })
            .nullable()
        ),
      })
      .nullable(),
  });

  const handleSubmit = (values) => {
    setFieldErrors([]);
    const normalFields = values.biField.filter((field) => !field.isGroup);
    if (normalFields.length) {
      values = {
        ...values,
        limit: parseInt(values.limit),
        biField: values.biField.map((field) => ({
          ...field,
          thresholdMin: field.thresholdMin ? parseFloat(field.thresholdMin) : null,
          thresholdMax: field.thresholdMax ? parseFloat(field.thresholdMax) : null,
        })),
        biDrilling: values.biDrilling.map((drillVal) => ({ ...drillVal, limit: parseInt(drillVal.limit) })),
        biDrillingLink: values.biDrillingLink.linkedReport ? values.biDrillingLink : undefined,
      };
      biService.upsertBiReportWithFields(values).then((data) => {
        if (!reportId) {
          navigate(`/app/bi/editor/${data.id}`);
        } else {
          setSelectedReport(data);
          setReRender(true);
        }
      });
    } else {
      setFieldErrors([{ id: uuidv4(), message: 'Nincs megadva mező!' }]);
    }
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="grid grid-cols-1 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem] overflow-auto">
      <div className="col-span-1 bg-white shadow rounded-lg overflow-auto custom-scrollbar">
        <Formik
          initialValues={initValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, validateField, setFieldValue }) => (
            <Form>
              <div className="h-full flex flex-col gap-1 overflow-auto">
                <div className="flex flex-col lg:flex-row gap-1 flex-1 overflow-auto">
                  <div className="lg:flex-[3] xl:flex-[2] 2xl:flex-[2] flex-1 overflow-auto custom-scrollbar">
                    <BiReportForm
                      values={values}
                      validateField={validateField}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      autocompleteData={autocompleteData}
                      getFields={getFields}
                      reportId={reportId}
                      defaultField={defaultField}
                    />
                  </div>
                  <div className="lg:flex-[8] xl:flex-[9] 2xl:flex-[11] flex-1 min-h-[450px] overflow-hidden">
                    {reportId ? (
                      <BiReport reportId={reportId} reRender={reRender} />
                    ) : (
                      <div>A riport mentés után jelenik meg!</div>
                    )}
                  </div>
                </div>

                <TabsUI activeTab={activeTab} handleTabClick={handleTabClick} alignToStart>
                  <div key={0} name={'Lekérdezés'}>
                    <div className="flex-1 overflow-auto custom-scrollbar min-h-[360px]">
                      <BiFieldForm
                        values={values}
                        validateField={validateField}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        defaultField={defaultField}
                        autocompleteData={autocompleteData}
                        fieldErrorState={{ fieldErrors, setFieldErrors }}
                      />
                    </div>
                  </div>
                  {values.reportType !== BiReportType.TABLE && (
                    <div key={1} name="Fúrás">
                      <div className="flex-1 overflow-auto custom-scrollbar min-h-[360px]">
                        <BiDrillForm
                          values={values}
                          validateField={validateField}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          defaultDrillField={defaultDrillField}
                          autocompleteData={autocompleteData}
                          fieldErrorState={{ fieldErrors, setFieldErrors }}
                          sourceData={sourceData}
                          selectedReport={selectedReport}
                        />
                      </div>
                    </div>
                  )}
                  {values.reportType !== BiReportType.TABLE && (
                    <div key={2} name="Szűrés">
                      <div className="flex-1 overflow-auto custom-scrollbar min-h-[360px]">
                        <BiFilterFields
                          values={values}
                          validateField={validateField}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          defaultDrillField={defaultDrillField}
                          autocompleteData={autocompleteData}
                          fieldErrorState={{ fieldErrors, setFieldErrors }}
                        />
                      </div>
                    </div>
                  )}
                </TabsUI>

                <div className="grid grid-cols-3 gap-x-4 mx-4 mb-5">
                  <div className="col-span-1 ">
                    <ButtonUI
                      text={'Mentés'}
                      className="bg-success"
                      type="submit"
                      disabled={!(values.biHeadId && values.reportType)}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BiEditorPage;
