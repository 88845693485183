import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'project';

const projectService = {
  getProjects: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/projects`,
      data,
    });
  },

  getProjectById: (projectId) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/${projectId}`,
    });
  },

  createProject: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateProject: (data, projectId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${projectId}`,
      data,
    });
  },

  deleteProject: (projectId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${projectId}`,
    });
  },

  addContactToList: (data, id) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/projectContact/${id}`,
      data,
    });
  },

  deleteContactFromList: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/projectContact/${id}`,
      data,
    });
  },

  updateContact: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/projectContact/${id}`,
      data,
    });
  },

  copyResources: (id, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  orderProject: (projectId, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/orderProject/${projectId}`,
      data,
    });
  },

  addCommunicationToList: (data, projectId) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/projectCommunication/${projectId}`,
      data,
    });
  },

  deleteCommunicationFromList: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/projectCommunication/${id}`,
      data,
    });
  },

  updateCommunication: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/projectCommunication/${id}`,
      data,
    });
  },

  uploadFile: (files, id, userId) => {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('userId', userId);

    return request({
      method: Methods.POST,
      resource: `${baseURL}/uploadFile/${id}`,
      formData: true,
      data: formData,
    });
  },

  deleteFile: (id, userId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/deleteFile/${id}`,
      data: { userId },
    });
  },
};

export default projectService;
