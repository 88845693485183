import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import NoPage from './components/NoPage/NoPage';
import Layout from './components/Navbar/Layout';
import { CompanyForm } from './components/Company/CompanyForm';
import { StatusForm } from './components/Status/StatusForm';
import Auth from './components/Auth/Auth';
import ProtectedRoute from './components/ProtectedRoute';
import RoleIndex from './components/Roles/RoleIndex';
import { SimpleTaskMain } from './components/SimpleTask/SimpleTaskMain';
import { ProjectGroupForm } from './components/ProjectGroupListitems/ProjectGroupForm';
import { PriorityForm } from './components/Priority/PriorityForm';
import { UserForm } from './components/Users/UserForm';
import { MachineForm } from './components/Machines/MachineForm';
import TypeForm from './components/Type/TypeForm';
import MyTodoTable from './components/MyTodo/MyTodoTable';
import { LoaderProvider } from './provider/LoaderProvider';
import LoaderUI from './components/Interface/LoaderUI';
import KanbanView from './components/Kanban/KanbanView';
import TaskevaluationForm from './components/TaskEvaluation/TaskevaluationForm';
import TaskGanttForm from './components/TaskGantt/TaskGanttForm';
import { SimpleTaskPhoneForm } from './components/SimpleTaskPhone/SimpleTaskPhoneForm';
import SapResourcePlanner from './components/SapResourcePlanner/SapResourcePlanner';
import ActiveTimePage from './components/ActiveTime/ActiveTimePage';
import { ProductionForm } from './components/Production/ProductionForm';
import { OperationForm } from './components/Operation/OperationForm';
import { QuantityUnitForm } from './components/QuantityUnit/QuantityUnitForm';
import { TaskReportForm } from './components/TaskReport/TaskReportForm';
import { ProductionReportForm } from './components/ProductionReport/ProductionReportForm';
import { CompanyLocationForm } from './components/CompanyLocation/CompanyLocationForm';
import { PropertyForm } from './components/Property/PropertyForm';
import { InnovationForm } from './components/Innovation/InnovationForm';
import { AttendancePage } from './components/Attendance/AttendancePage';
import { AbsencePage } from './components/Absence/AbsencePage';
import { ResourceOnePage } from './components/Resources/ResourceOne/ResourceOnePage';
import { ResourceTwoPage } from './components/Resources/ResourceTwo/ResourceTwoPage';
import { ResourceThreePage } from './components/Resources/ResourceThree/ResourceThreePage';
import { ResourceFourPage } from './components/Resources/ResourceFour/ResourceFourPage';
import { ProjectResourcePlanner } from './components/ProjectResourcePlanner/ProjectResourcePlanner';
import { EmployeeResourcePlanner } from './components/EmployeeResourcePlanner/EmployeeResourcePlanner';
import { ProjectInfoPanelPage } from './components/ProjectInfoPanel/ProjectInfoPanelPage';
import { UserProfilePage } from './components/UserProfile/UserProfilePage';
import { ComplaintPage } from './components/ProductComplaint/ComplaintPage';
import { FunnelView } from './components/Kanban/FunnelView';
import BiDataSourcePage from './components/BusinessIntelligence/BiDataSource/BiDataSourcePage';
import BiEditorPage from './components/BusinessIntelligence/BiEditor/BiEditorPage';
import BiQueryPage from './components/BusinessIntelligence/BiQueries/BiQueryPage';
import BiPage from './components/BusinessIntelligence/BiReport/BiPage';
import Dashboard from './components/BusinessIntelligence/Dashboard/Dashboard';
import DashboardAdmin from './components/BusinessIntelligence/Dashboard/DashboardAdmin';

import { MenuProvider } from './context/MenuContext';
import { UsersProvider } from './context/UsersContext';
import { PageProvider } from './context/PageContext';
import { ParamProvider } from './context/ParamContext';
import { ProjectMain } from './components/Project/ProjectMain';

const App = () => {
  return (
    <LoaderProvider>
      <PageProvider>
        <MenuProvider>
          <ParamProvider>
            <UsersProvider>
              <LoaderUI />
              <SnackbarProvider hideIconVariant>
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Auth />} />
                    <Route path="/app" element={<ProtectedRoute element={<Layout />} />}>
                      <Route index element={<MyTodoTable />} />
                      <Route path="project" element={<ProjectMain />} />
                      <Route path="company" element={<CompanyForm />} />
                      <Route path="status" element={<StatusForm />} />
                      <Route path="projectGroup" element={<ProjectGroupForm />} />
                      <Route path="Categories" element={<PriorityForm />} />
                      <Route path="type" element={<TypeForm />} />
                      <Route path="machine" element={<MachineForm />} />
                      <Route path="operation" element={<OperationForm />} />
                      <Route path="quantityunit" element={<QuantityUnitForm />} />
                      <Route path="companylocation" element={<CompanyLocationForm />} />
                      <Route path="properties" element={<PropertyForm />} />
                      <Route path="complaint" element={<ComplaintPage />} />

                      <Route path="resourceOne" element={<ResourceOnePage />} />
                      <Route path="resourceTwo" element={<ResourceTwoPage />} />
                      <Route path="resourceThree" element={<ResourceThreePage />} />
                      <Route path="resourceFour" element={<ResourceFourPage />} />

                      <Route path="simpleTask" element={<SimpleTaskMain />} />
                      <Route path="mytodo" element={<MyTodoTable />} />
                      <Route path="simpleTaskPhone" element={<SimpleTaskPhoneForm />} />
                      <Route path="production" element={<ProductionForm />} />
                      <Route path="taskReport" element={<TaskReportForm />} />
                      <Route path="productionreport" element={<ProductionReportForm />} />
                      <Route path="innovation" element={<InnovationForm />} />
                      <Route path="absence" element={<AbsencePage />} />
                      <Route path="attendance" element={<AttendancePage />} />

                      <Route path="user" element={<UserForm />} />
                      <Route path="roles" element={<RoleIndex />} />

                      <Route path="projectResourcePlanner" element={<ProjectResourcePlanner />} />
                      <Route path="employeeResourcePlanner" element={<EmployeeResourcePlanner />} />
                      <Route path="projectInfoPanel" element={<ProjectInfoPanelPage />} />
                      <Route path="userProfile" element={<UserProfilePage />} />

                      <Route path="taskkanban" element={<KanbanView />} />
                      <Route path="funnelView" element={<FunnelView />} />
                      <Route path="taskevaluation" element={<TaskevaluationForm />} />
                      <Route path="taskgantt" element={<TaskGanttForm />} />

                      <Route path="activeTime" element={<ActiveTimePage />} />
                      <Route path="sapresourceplanner" element={<SapResourcePlanner />} />

                      <Route path="bi">
                        <Route path="datasource" element={<BiDataSourcePage />} />
                        <Route path="query" element={<BiQueryPage />} />
                        <Route path="editor/:reportId?" element={<BiEditorPage />} />
                        <Route path="biPage/:reportId" element={<BiPage />} />
                      </Route>
                      <Route path="dashboard/:dashboardId" element={<Dashboard />} />
                      <Route path="dashboardAdmin" element={<DashboardAdmin />} />

                      <Route path="*" element={<NoPage />} />
                    </Route>
                  </Routes>
                </BrowserRouter>
              </SnackbarProvider>
            </UsersProvider>
          </ParamProvider>
        </MenuProvider>
      </PageProvider>
    </LoaderProvider>
  );
};

export default App;
